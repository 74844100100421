import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import ComingSoon from './ComingSoon';
import {Field, Form} from '../../shared/FormComponents';
import FileUploader from '../../shared/FileUploader';
import withPopup from '../../../HOC/withPopup';
import {
    getImportSessionInitialValues,
    requestEligibilityImportSessionCreating,
    requestEligibilityImportSessions,
    requestEligibilityImportSessionUpdating,
    requestImportsJsonrpc,
    requestIndividualConfig
} from '../../../actions/adminPortal';
import {redirectTo} from '../../../actions/general';
import {getProfileInfo} from '../../../selectors/general';
import {
    ELIGIBILITY_IMPORT_STATUSES,
    FILE_FORMATS,
    FORMS,
    IMPORT_CONFIG_TYPES,
    IMPORT_SESSION_IN_PROGRESS_STATUSES,
    JSON_RPC_RESULTS,
    LAST_IMPORT_SESSION_IN_PROGRESS_POPUP_DATA,
    LAST_IMPORT_SESSION_WRITING_POPUP_DATA,
    ROUTES
} from '../../../constants';
import {
    equal,
    generateUniqueId,
    getEqual,
    matchFileName,
    parseQuery,
    validateFileNamesPattern,
    validateRequired
} from '../../../utils';
import {getIndividualConfig} from '../../../helpers';
import {showToast} from '../../../actions/shared';

const POPUP_ID = 'IndividualImportUploadFilePopup';

const TOAST_VISIBILITY_TIME = 10000;

/* istanbul ignore next */
const validate = (values, pattern) => {
    return {
        files: validateFileNamesPattern(values.files, pattern, matchFileName) || validateRequired(values.files)
    };
};

const IndividualImportUploadFileForm = ({openPopup, closePopup, configType}) => {
    const dispatch = useDispatch();
    const {email: userEmail} = useSelector(getProfileInfo);
    const [initialValues, setInitialValues] = useState({});
    const [filePattern, setFilePattern] = useState(null);
    const {group_alias: groupAlias, partner} = useParams();
    const {search} = useLocation();
    const {group_name: groupName, autoImportId} = parseQuery(search, {arrayFormat: 'none'});
    const {push} = useHistory();
    const isFormAvailable = [IMPORT_CONFIG_TYPES.launch, IMPORT_CONFIG_TYPES.maintenance].some(getEqual(configType));

    const getLastSession = async (offset = 0) => {
        const {sessions} = await dispatch(requestEligibilityImportSessions({limit: 1, offset, company_alias: groupAlias}));
        return sessions[0] || {};
    };

    useEffect(() => {
        (async () => {
            const [
                lastSession,
                {data: configs}
            ] = await Promise.all([
                getLastSession(),
                dispatch(requestIndividualConfig({companyAlias: groupAlias}))
            ]);
            const {id: configId, manual_file_pattern: filePattern} = getIndividualConfig(false, configs);

            const initialValues = equal(lastSession.status, ELIGIBILITY_IMPORT_STATUSES.new)
                ? lastSession
                : await dispatch(getImportSessionInitialValues({companyAlias: groupAlias, import_config_id: configId}));

            setInitialValues(initialValues);
            setFilePattern(filePattern);
        })();
    }, [groupAlias, userEmail]);

    const runImportSession = async session => {
        const jsonrpcObj = {jsonrpc: '2.0', method: 'run_individual_import', id: generateUniqueId(), params: {session}};
        const {jsonrpc} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

        if (!equal(jsonrpc?.result, JSON_RPC_RESULTS.success)) {
            return false;
        }

        if (autoImportId) {
            dispatch(showToast({content: 'Import Uploaded!', visibilityTime: TOAST_VISIBILITY_TIME}));
        }

        const route = (autoImportId)
            ? ROUTES.eligibilityImports
            : `${ROUTES.importsIndividual}/${groupAlias}?group_name=${encodeURIComponent(groupName)}&show_submit_alert=true`;
        dispatch(redirectTo(route));
    };

    const prepareSession = async session => {
        const requestSession = session.id ? requestEligibilityImportSessionUpdating : requestEligibilityImportSessionCreating;
        const {isSuccess, submissionErrors, data: importSession} = await dispatch(requestSession(session.id ? session.id : {...session, ...autoImportId && {reference_id: Number(autoImportId)}}, {files: session.files}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(importSession);
        runImportSession(importSession);
        closePopup();
    };

    const onOpenPopup = (popupContent, actionBar, title) => {
        const popupProps = {title, actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const importSessionSubmit = async session => {
        const lastSession = await getLastSession(session.id && 1);

        if (equal(lastSession.status, ELIGIBILITY_IMPORT_STATUSES.writing)) {
            onOpenPopup(LAST_IMPORT_SESSION_WRITING_POPUP_DATA.content, <Button onClick={closePopup}>Okay</Button>);

            return false;
        }

        if (IMPORT_SESSION_IN_PROGRESS_STATUSES.some(status => equal(lastSession.status, status))) {
            const actionBar = (
                <React.Fragment>
                    <Button onClick={closePopup} type={BUTTON_TYPES.secondary}>Cancel</Button>
                    <Button data-testid='continue-button' className='ml-10' onClick={() => prepareSession(session)}>Yes, Continue</Button>
                </React.Fragment>
            );
            onOpenPopup(LAST_IMPORT_SESSION_IN_PROGRESS_POPUP_DATA.content, actionBar, LAST_IMPORT_SESSION_IN_PROGRESS_POPUP_DATA.title);

            return false;
        }

        prepareSession(session);
    };

    const onSubmit = useCallback(async ({isSubmit, ...values}) => {
        if (isSubmit) {
            importSessionSubmit(values);
            return false;
        }

        const requestSession = values.id ? requestEligibilityImportSessionUpdating : requestEligibilityImportSessionCreating;
        const {isSuccess, submissionErrors, data: session} = await dispatch(requestSession(values.id ? values.id : {...values, ...autoImportId && {reference_id: Number(autoImportId)}}, {files: values.files}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(session);
    }, [dispatch, importSessionSubmit]);

    return isFormAvailable ? (
        <Form name={FORMS.uploadIndividualImport} initialValues={initialValues} validate={values => validate(values, filePattern)} onSubmit={onSubmit}>
            {({handleSubmit, valid: isValid, form}) => {
                const onSubmit = () => {
                    form.change('isSubmit', true);
                    handleSubmit();
                };

                return (
                    <form className='upload-eligibility-file-form' onSubmit={handleSubmit}>
                        <Field name='isSubmit'>{props => <Input {...props} type='hidden'/>}</Field>

                        <ContentSection className='mt-25'>
                            <Field name='files'>
                                {props => <FileUploader {...props} isMultiple maxCount={1} isPrivateFile size='xs' accept={[FILE_FORMATS.csv, FILE_FORMATS.txt, FILE_FORMATS.xls, FILE_FORMATS.xlsx].map(format => `.${format}`)} label='Upload File' isRequired/>}
                            </Field>
                        </ContentSection>

                        <Row className='mt-20'>
                            <Column xs/>
                            <Column constant className='pr-5'>
                                <Button data-testid='back-button' onClick={() => push(`${search}#configuration`)} className='mr-10' type={BUTTON_TYPES.secondary}>Back to {partner && 'Individual'} Config</Button>
                                <Button data-testid='save-button' disabled={!isValid} onClick={handleSubmit} className='mr-10' type={BUTTON_TYPES.secondary}>Save Progress</Button>
                                <Button data-testid='submit-button' disabled={!isValid} onClick={onSubmit}>Submit</Button>
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    ) : <ComingSoon/>;
};

IndividualImportUploadFileForm.propTypes = {
    configType: PropTypes.string,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {IndividualImportUploadFileForm as TestableIndividualImportUploadFileForm};
export default withPopup(POPUP_ID)(IndividualImportUploadFileForm);
