import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Link from '@frontend/ui-kit/Components/Link';
import Table from '@frontend/ui-kit/Components/Table';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import ImportCard from '../ImportCard';
import {requestEligibilityImportSessionErrors, requestEligibilityImportSessionStats} from '../../../actions/adminPortal';
import {redirectTo, requestTPAPartner} from '../../../actions/general';
import {ROUTES} from '../../../constants';
import {
    convertDateToTimeZone,
    getUTCDate,
    formatDate,
    getFileName,
    getFormattedIntervalToDuration,
    omit,
    parseQuery,
    stringifyQueryParams,
    toCapitalize
} from '../../../utils';
import './index.scss';

const ImportBlockerErrorsReport = () => {
    const dispatch = useDispatch();
    const {id: importId, group_alias: groupAlias, partner: partnerId} = useParams();
    const {search} = useLocation();
    const [blockersErrors, setBlockersErrors] = useState([]);
    const [{import_info: importInfo = {}}, setImportStats] = useState({});
    const {additional_data: additionalData = {}, created_at: createdAt, updated_at: updatedAt, files = []} = importInfo;
    const [partnerName, setPartnerName] = useState(null);

    useEffect(() => {
        (async () => {
            const [
                {data: importStats},
                {data: errors},
                {partner} = {}
            ] = await Promise.all([
                dispatch(requestEligibilityImportSessionStats(importId)),
                dispatch(requestEligibilityImportSessionErrors(importId)),
                ...partnerId ? [dispatch(requestTPAPartner(partnerId))] : []
            ]);

            setImportStats(importStats);
            setBlockersErrors(errors?.blockers);
            if (partner?.tpa_name) {
                setPartnerName(partner?.tpa_name);
            }
        })();
    }, []);

    const getFile = url => <Link className='file-name' href={url} target='_blank'>{decodeURIComponent(getFileName(url))}</Link>;

    const getTotalUploadTime = () => <span className='total-upload-time'>{getFormattedIntervalToDuration(createdAt, updatedAt)}</span>;

    const importInformation = [
        {title: 'Date', value: `${formatDate(convertDateToTimeZone(getUTCDate(createdAt)), 'MM/dd/yyyy H:mm')} CST`},
        {title: 'Total Upload Time', value: getTotalUploadTime()},
        {title: 'File Name', value: getFile(files[0])},
        {title: 'Uploaded By', value: toCapitalize(additionalData.created_by)}
    ];

    const onRedirectToValidationReport = () => {
        const enhancedSearch = stringifyQueryParams(omit(parseQuery(search), ['error_type']));
        const route = partnerId
            ? `${ROUTES.importsTpa}/${partnerId}/validation_report/${importId}?${enhancedSearch}`
            : `${ROUTES.importsIndividual}/${groupAlias}/validation_report/${importId}?${enhancedSearch}`;
        dispatch(redirectTo(route));
    };

    const onRedirectToFileUpload = () => {
        const enhancedSearch = stringifyQueryParams({...omit(parseQuery(search), ['error_type']), autoImportId: importId});
        const route = partnerId
            ? `${ROUTES.importsTpa}/${partnerId}/${groupAlias}/group_config?${enhancedSearch}#upload_file`
            : `${ROUTES.importsIndividual}/${groupAlias}/group_config?${enhancedSearch}#upload_file`;
        dispatch(redirectTo(route));
    };

    const onRedirectToDetails = (columnName, errorType) => {
        const route = partnerId
            ? `${ROUTES.importsTpa}/${partnerId}/${groupAlias}/blocker_errors_report/${importId}/${columnName}?partner_name=${partnerName}&error_type=${errorType}`
            : `${ROUTES.importsIndividual}/${groupAlias}/blocker_errors_report/${importId}/${columnName}?group_name=${additionalData?.company_name}&error_type=${errorType}`;
        dispatch(redirectTo(route));
    };

    const getTableColumns = () => {
        const getActions = ({cell, value}) => value
            ? <Button data-testid='view-button' type={BUTTON_TYPES.tertiary} onClick={() => onRedirectToDetails(value, cell?.row?.original?.error_type)}>View</Button>
            : <Link data-testid='view-button' href={importInfo?.additional_data?.slack_logs_url} target='_blank'>View</Link>;

        return [
            {Header: 'Description', accessor: 'error_type'},
            {Header: 'Explanation', accessor: 'explanation'},
            {Header: '# of Members', accessor: 'ees_count', width: 100},
            {Header: 'Actions', accessor: 'column_name', width: 100, Cell: getActions}
        ];
    };

    const errorsTableProps = {
        isFilterable: false,
        isSortable: false,
        isCellTooltip: false,
        className: 'pb-10',
        columns: getTableColumns()
    };

    return (
        <div className='import-blocker-errors-report'>
            <Alert className='mb-20' type={ALERT_TYPES.danger} description={<span>The eligibility file was partially submitted to database; validation report can be found <Button data-testid='validation-report-button' className='text-button' type={BUTTON_TYPES.tertiary} onClick={onRedirectToValidationReport}>here</Button>. Review the blockers below for members that were not processed and action accordingly.</span>}/>

            <Row middle='xs'>
                <Column sm>
                    <Heading type={HEADING_TYPES['5']}>{additionalData.company_name}</Heading>
                    <Heading type={HEADING_TYPES['1']}>Blocker Errors Report</Heading>
                </Column>
                <Column>
                    <Button data-testid='manual-file-upload-button' onClick={onRedirectToFileUpload} type={BUTTON_TYPES.primary} className='mr-6'>
                        Manual File Upload
                    </Button>
                </Column>
            </Row>

            <ContentSection className='mt-20'>
                <Row className='mt-10' rowGap='md'>
                    {importInformation.map(({title, value}) => (
                        <Column key={title} sm={3}>
                            <ImportCard title={title} value={value}/>
                        </Column>
                    ))}
                </Row>

                <Collapse isOpened className='mt-10' hasCollapseIcon initiator={<Heading type={HEADING_TYPES['4']}>Blocker Errors ({blockersErrors.length})</Heading>} content={<Table {...errorsTableProps} data={blockersErrors}/>}/>
            </ContentSection>
        </div>
    );
};

export {ImportBlockerErrorsReport as TestableImportBlockerErrorsReport};
export default React.memo(ImportBlockerErrorsReport);
